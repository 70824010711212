<template>
  <section id="program" class="marquee marquee-left" :class="sectionClass">
    <div class="name">radiopolis.xyz</div>

    <div class="now-playing marquee-inner" ref="marquee">
      <div>+++</div>
<!--      <div class="txt-tf-uppercase">Now:</div>-->
      <div v-html="this.program" ref="program"/>
      <div>+++</div>
    </div>
  </section>
</template>

<script>
import {getCurrentProgram} from "@/api";

export default {
  name: "SectionProgram",
  props: {
    sectionClass: String
  },
  data() {
    return {
      program: '',
      longWidth: false,
      marqueeRepeat: 2
    }
  },
  computed: {
  },
  created() {
    getCurrentProgram().then(res => {
      this.program = res;
    });
  },
  mounted() {
    this.$refs.marquee.style.width = 3;
  }
}
</script>

<style scoped>
#program {
  background-color: var(--color-yellow);
  height: var(--section-height);
  position: relative;
}

#program.small {
  height: var(--section-height-small);
}

#program.small > .now-playing {
  font-size: var(--section-height-small);
}

#program > .name {
  font-size: calc(var(--section-height) / 1.8);
  font-style: normal;
  line-height: 0.81;
  font-variant: normal;
  font-weight: bold;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

#program > .now-playing {
  font-size: var(--section-height);
  font-style: normal;
  line-height: 1;
  font-variant: normal;
  font-weight: bold;
  flex-wrap: nowrap;
  display: flex;
  height: var(--section-height);
  overflow: hidden;
  position: absolute;
  padding-right: 1rem;
  padding-left: 1rem;
  align-items: center;
}

#program > .now-playing > div {
  background-color: var(--color-yellow);
  padding: 0 1rem;
}

#program:hover > .now-playing {
  animation-play-state: paused;
}

#program > .now-playing > div {
}

@media (max-width: 992px) {
  #program > .now-playing {
    font-size: calc(var(--section-height) * 0.8);
  }
}

</style>
